const dashBoardTranslation = {
  mode: 'ko',
  menu: '대시보드',
  recordingMenu: '녹화 현황',
  deviceMenu: '장비 현황',
  errorMenu: '오류 현황',
  sessionMenu: '세션 현황',

  recordingDailyCount: '일일 녹화 & 바코드 수',
  recordingMonthlyCount: '월별 녹화 수',
  barcodeCountByHospital: '병원별 바코드 수',
  deviceVersionCount: '버전별 장비 현황',
  inactiveDevice: '활동 없는 장비 리스트',
  updateDevice: '업데이트 필요한 장비 리스트',
  uninstalledDevice: '미설치 장비 리스트',
  offlineDevice: '오프라인 장비 리스트',
  capture: '캡쳐보드 연결 상태',
  upload: '업로드 상태',
  captureStatus: '캡쳐보드 연결 현황',
  uploadStatus: '업로드 현황',
  captureError: '캡쳐보드 연결 오류 장비 리스트',
  uploadError: '업로드 실패 리스트',
  sessionTable: '세션 리스트',
  sessionDailyCount: '일일 세션 수',

  renew: '업데이트 시간',
  all: '전체',
  installed: '설치',
  online: '온라인',
  uninstalled: '미설치',
  offline: '오프라인',
  error: '캡쳐보드 오류',

  disconnected: '연결 끊김',
  connected: '연결됨',
  noInfo: '정보 없음',
  fail: '실패',
  success: '성공',

  daily: '일별',
  monthly: '월별',
  hospital: '병원명',
  hospitalRoom: '진료실명',
  device: '장비명',
  barcode: '바코드',
  city: '시/군/구',
  barcodeCount: '바코드 수',
  recordingCount: '녹화 수',
  activity: '마지막 활동',
  version: '버전',
  fileId: '파일 ID',
  scannedAt: '스캔 시간',

  count: '세션 수',
  status: '세션 상태',
  captureCount: '캡쳐 수',
  startedAt: '스캔 시간',
  motionPassedAt: '모션 통과 시간',
  stoppedAt: '종료 바코드 스캔 시간',
  finishedAt: '세션 종료 시간',
  uploadedAt: '업로드 시간',

  created: '바코드 스캔',
  motion: '모션 감지 중',
  recording: '녹화 중',
  pause: '일시 정지',
  stopped: '종료 바코드 스캔',
  finished: '후처리 작업 완료',
  uploaded: '업로드 완료',
  sessionError: '오류',
  unknown: '알 수 없음',

  searchSession: '세션 검색',
  howToSearch: '검색 도움말',
  help: '도움말',
  howToSearchMessage: '선택한 날짜 이전 기준으로 활동이 없는 장비 조회 (기본값: 30일 이전)',
  howToUpdateMessage: '2.8 이하의 장비는 반드시 업데이트 필요',
};

export default dashBoardTranslation;
